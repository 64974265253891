import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
const Page = ({ data, location }) => {
    const page = data.ghostPage;

    return (
        <>
            <MetaData data={data} location={location} type="website" />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div
                    class="flex items-center w-full justify-center"
                    style={{
                        position: "relative",
                        height: "calc(50vh - 6rem)",
                        top: 0,
                        left: 0,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                >
                    <h1
                        className="content-title"
                        style={{ color: "white", zIndex: 2, marginBottom: 0 }}
                    >
                        {page.title}
                    </h1>
                    <div
                        id="bg"
                        style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: `url('${page.feature_image}')`,
                            position: "absolute",
                            height: "50vh",
                            top: 0,
                            left: 0,
                            width: "100%",
                        }}
                    />
                </div>
                <div className="container">
                    {/* <div
                        id="bg-spacer"
                        class="flex items-center w-full justify-center"
                        style={{
                            position: "relative",
                            height: "calc(50vh - 6rem)",
                            top: 0,
                            left: 0,
                            width: "100%",
                        }}
                    ></div> */}

                    <article className="content">
                        {/* The main page content */}
                        <section
                            className="content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: page.html }}
                        />
                    </article>
                </div>
            </Layout>
        </>
    );
};

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Page;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`;
